body {
	.box-content {
		p {
			line-height: 24px;
			font-size: 16px;
		}
	}
	div.step {
		form {
			.form-group {
				input {
					width: 100%;
					margin: 0 0 10px;
					padding: 8px;
					border-radius: 2px;
					border: 1px solid #ccc;
				}
				button {
					width: 100%;
					color: white;
					background-color: #ea530e;
				}
			}
		}
	}
}