.pagination {
	padding-bottom: 20px;
	ul {
		background-color: @orange;
		float: left;
		width: 100%;
		li {
			float: left;
			width: 50%;
			a {
				text-transform: uppercase;
				display: block;
				color: white;
				font-size: 22px;
				font-weight: 600;
				position: relative;
				&:after {

					content: '';
					position: absolute;
					width: 10px;
					height: 20px;
					background-repeat: no-repeat;
					background-size: 10px;
					background-position: 0 3px;
				}
				&:hover {
					color: rgba(255,255,255,0.7);
				}
			}
			&:first-child {
				a {
					padding: 20px 0 20px 40px;
					&:after {
						margin-top: 10px;
						left: 20px;
						background-image: url('/img/icons/arrow_l_min.png');
					}
				}
			}
			&:last-child {
				text-align: right;
				a {
					padding: 20px 40px 20px 0;
					&:after {
						margin-top: 10px;
						right: 20px;
						background-image: url('/img/icons/arrow_r_min.png');
					}
				}
			}
		}
	}
}