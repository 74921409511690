body .header-top img {
  position: absolute;
  top: -30px;
}
body .header-top .col-inner div {
  position: relative;
}
body .header-top .col-inner div.phone {
  float: right;
}
body .header-top .col-inner div.main-logo {
  position: relative;
  z-index: 999;
}
body .header-top .col-inner div:after,
body .header-top .col-inner div:before {
  content: '';
  z-index: 0;
  position: absolute;
  height: 1000%;
  bottom: 0;
  width: 10000%;
}
body .header-top .col-inner div:after {
  left: 100%;
}
body .header-top .col-inner div:before {
  right: 100%;
}
body .header-top .col-inner div ul {
  display: flex;
  flex-direction: row;
}
body .header-top .col-inner div ul li {
  margin: 0 28px 0 0;
}
body .header-top .col-inner div ul li:last-child {
  margin: 0;
}
body .header-waypoint-sticky.header-main img {
  width: 80px!important;
  top: 5px;
}
body .header-waypoint-sticky.header-main div.phone {
  display: none;
}
@media (max-width: 1199px) {
  body header.header-main img {
    width: 140px!important;
  }
  body section.page-wrapper {
    padding: 0!important;
  }
}
@media (max-width: 991px) {
  body .sidebar-wrapper {
    width: 100%!important;
    position: relative!important;
  }
  body section {
    padding: 50px 0;
  }
  body section.page-wrapper {
    padding: 50px 0 0!important;
  }
  body header.header-main img {
    position: relative;
    min-width: 160px!important;
    top: 0;
  }
  body header.header-main div.header-top {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  body header.header-main div.header-top div.phone {
    display: none;
  }
  body header.header-main div.header-bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 0 0;
  }
  body header.header-main div.header-bottom > div {
    width: 100%!important;
    max-width: 100%!important;
  }
  body header.header-main div.header-bottom ul.nav-mini-right {
    float: none;
    display: flex;
    justify-content: center;
  }
  body header.header-main div.header-bottom ul.nav-mini-right > li {
    margin-right: 30px;
  }
  body header.header-main div.header-bottom ul.nav-mini-right > li > a {
    font-size: 20px;
  }
  body header.header-main .btn.btn-toggle:before {
    width: 20px;
    height: 23px;
    line-height: 18px;
    font-size: 29px;
  }
}
@media (max-width: 991px) {
  div.main-logo a {
    display: flex;
    justify-content: center;
  }
  body div.body-inner {
    overflow-x: hidden;
  }
  body header.header-main div.header-bottom ul.nav-mini-right > li {
    margin-right: 0;
  }
  body header.header-main .btn.btn-toggle:before {
    width: 45px;
    font-size: 45px;
  }
  body section.page-wrapper {
    padding: 0 15px!important;
  }
  body div.content-wrapper {
    padding-right: 0!important;
  }
  body .page-wrapper .sidebar-container {
    margin: 40px 0 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  body .main-wrapper .page-static .sidebar-container .sidebar-wrapper > ul > li > ul > li {
    padding: 0!important;
  }
  body .main-wrapper .page-static .sidebar-container .sidebar-wrapper > ul > li > ul > li > a {
    margin: 15px 0;
    font-size: 18px;
  }
  body .main-wrapper .page-static .sidebar-container .sidebar-wrapper > ul li ul ul {
    padding: 0;
  }
  body .main-wrapper .page-static .sidebar-container .sidebar-wrapper > ul li ul ul li {
    padding: 0;
  }
  body .main-wrapper .page-static .sidebar-container .sidebar-wrapper > ul li ul ul li > a {
    margin: 10px 0;
    font-size: 16px;
  }
}
body .page-static .content-wrapper h2 {
  position: relative;
  text-indent: 0 0 0 30px;
  color: #ea530e;
}
body .page-static .content-wrapper h2:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 25%;
  bottom: 25%;
  background-color: #eee;
  left: 0;
}
body .page-static .content-wrapper h3 {
  font-size: 26px;
}
body .page-static .content-wrapper p {
  font-size: 18px;
  line-height: 24px;
  padding: 5px 0;
  text-align: justify;
}
body .page-static .content-wrapper p.information {
  background-color: #eee;
  padding: 15px 30px;
  color: black;
  font-weight: 600;
  font-size: 20px;
}
body .page-static .content-wrapper ul {
  padding: 0 0 10px 30px;
}
body .page-static .content-wrapper ul li {
  margin: 0 0 0 30px;
  list-style-type: disc;
  font-size: 18px;
  line-height: 24px;
  padding: 2px 0;
}
body .page-static .content-wrapper ul li:last-child {
  border-bottom: none;
}
body .page-static .content-wrapper ul:last-child {
  padding: 0 15px 0;
}
body .page-static .content-wrapper ul.links {
  background-color: #eee;
  padding: 20px 30px;
}
body .page-static .content-wrapper ul.links li {
  margin: 0;
  list-style-type: none;
}
body .page-static .content-wrapper ul.links li a {
  font-weight: 600;
}
body .page-static .sidebar-container {
  overflow: hidden;
  background-color: #ea530e;
}
body .page-static .sidebar-container .sidebar-wrapper a {
  color: white;
}
body .page-static .sidebar-container .sidebar-wrapper > ul {
  margin: 0 0 40px;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li {
  padding: 10px 0;
  line-height: 22px;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li.active > a {
  font-weight: 700;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li a {
  position: relative;
  transition: all 0.5s;
  left: 0;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li a:hover {
  left: 10px;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li ul ul {
  padding: 0 0 0 30px;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li li.active {
  padding: 20px 0 15px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li li.active a {
  font-weight: 700;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li li.active ul li {
  padding: 0 0 10px;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li li.active ul li a {
  font-weight: 400;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li li.active:after,
body .page-static .sidebar-container .sidebar-wrapper > ul li li.active:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 200%;
  background-color: rgba(255, 255, 255, 0.2);
  top: 0;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li li.active:before {
  left: 100%;
}
body .page-static .sidebar-container .sidebar-wrapper > ul li li.active:after {
  right: 100%;
}
body .page-static .sidebar-container .sidebar-wrapper > ul > li > a {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 40px;
  margin: 0 0 30px;
  display: block;
}
body .page-static .sidebar-container .sidebar-wrapper > ul > li > ul > li {
  padding: 20px 0 10px;
}
body .page-static .sidebar-container .sidebar-wrapper > ul > li > ul > li > a {
  margin: 0 0 10px;
  display: block;
}
body .page-static .sidebar-container .sidebar-wrapper > ul > li > ul > li:last-child {
  border: none;
}
body .page-static .sidebar-container .sidebar-wrapper.news h4 {
  color: white;
}
body .page-static .sidebar-container .sidebar-wrapper.news ul li a {
  text-transform: initial;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
}
body .content-wrapper div.partenaires_liste {
  display: flex;
  flex-direction: column;
}
body .content-wrapper div.partenaires_liste ul {
  display: flex;
  flex-direction: row;
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid #eee;
}
body .content-wrapper div.partenaires_liste ul li {
  list-style-type: none;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
}
body .content-wrapper div.partenaires_liste ul li:first-child {
  text-align: center;
}
body .content-wrapper div.partenaires_liste ul li img {
  width: inherit;
  max-width: 200px;
  justify-content: center;
}
body div.image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}
body .activities {
  display: flex;
  flex-direction: column;
}
body .activities > figure {
  margin: 0 0 30px;
  padding: 0 0 30px;
  border-bottom: 2px solid #eee;
}
body .activities > figure i {
  margin: 0 10px 0 0;
}
body .activities > figure ul.detail li {
  list-style-type: disc;
  margin: 0 0 0 30px;
  line-height: 20px;
}
body .activities > figure:last-child {
  border: none;
  padding: 0;
}
body .box-content p {
  line-height: 24px;
  font-size: 16px;
}
body div.step form .form-group input {
  width: 100%;
  margin: 0 0 10px;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid #ccc;
}
body div.step form .form-group button {
  width: 100%;
  color: white;
  background-color: #ea530e;
}
#datepicker.agenda_home {
  margin: 0;
  height: 462px;
  position: relative;
  z-index: 999;
}
#datepicker.agenda_home .ui-datepicker-header .ui-datepicker-title {
  cursor: pointer;
  width: 280px;
  margin-left: 37px;
}
#datepicker.agenda_home .ui-datepicker-header .ui-datepicker-title span.ui-datepicker-month,
#datepicker.agenda_home .ui-datepicker-header .ui-datepicker-title span.ui-datepicker-year {
  color: white;
}
#datepicker.agenda_home .ui-datepicker-header .ui-datepicker-title:hover {
  opacity: 0.7;
}
#datepicker.agenda_home .ui-datepicker-header .ui-datepicker-prev {
  background-image: url('/web/images/arrow_l_min.png');
}
#datepicker.agenda_home .ui-datepicker-header .ui-datepicker-next {
  background-image: url('/web/images/arrow_r_min.png');
}
#datepicker.agenda_home .ui-datepicker-calendar tbody tr td a {
  color: white;
}
#datepicker.agenda_home .ui-datepicker-calendar tbody tr td span {
  color: #f69568;
}
#datepicker.agenda_home .ui-datepicker-calendar tbody tr td.ui-datepicker-today a:after {
  background-color: #f47942 !important;
}
#datepicker.agenda_home .ui-datepicker-calendar tbody tr td.with-events a {
  color: white;
}
#datepicker.agenda_home .ui-datepicker-calendar tbody tr td.with-events a:after {
  background-color: #ba420b;
}
#datepicker.agenda_home:after {
  background-color: #f37338;
}
/*** Datepicker Home ***/
.ui-datepicker {
  display: none;
  float: left;
  margin: 0;
  padding: 10px 25px 10px 25px;
  background: #ea530e;
  position: relative;
}
.ui-datepicker .ui-datepicker-header {
  height: 90px;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  position: absolute;
  background-repeat: no-repeat;
  width: 17px;
  height: 28px;
  top: 43px;
  text-indent: -999px;
  overflow: hidden;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover {
  opacity: 0.7;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  background-image: url('/images/arrow_sprite.png');
  background-position: 3px 2px;
  left: 35px;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  background-image: url('/images/arrow_sprite.png');
  right: 35px;
  background-position: 1px 2px;
  width: 16px;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title span {
  display: inline-block;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title span.ui-datepicker-month {
  color: white;
  font-size: 28px;
  width: 100%;
  height: 6px;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title span.ui-datepicker-year {
  color: #f7a680;
  font-size: 18px;
}
.ui-datepicker .ui-datepicker-calendar {
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0 0 0 5px;
}
.ui-datepicker .ui-datepicker-calendar thead {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  color: white;
}
.ui-datepicker .ui-datepicker-calendar thead th {
  text-align: center;
  height: 63px;
}
.ui-datepicker .ui-datepicker-calendar tbody {
  text-align: center;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td {
  font-size: 18px;
  height: 52px;
  line-height: 50px;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td a {
  padding: 0;
  color: white;
  display: block;
  float: left;
  position: relative;
  height: 40px;
  width: 50px;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td a:after {
  content: '';
  position: absolute;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  left: 0;
  top: 4px;
  left: 5px;
  z-index: -1;
  opacity: 1;
  transition: opacity 0.5s;
  webkit-transition: opacity 0.5s;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td a:hover:after {
  opacity: 0.5;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td span {
  color: #f9b698;
  float: left;
  height: 40px;
  width: 40px;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td.ui-datepicker-today a {
  color: white;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td.ui-datepicker-today a:after {
  background-color: #f58450;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td.with-events a:after {
  background-color: #ba420b;
}
.ui-datepicker .ui-datepicker-calendar tbody tr:last-child td {
  padding: 0 0 20px;
}
.ui-datepicker:after {
  content: '';
  position: absolute;
  top: 100px;
  width: 100%;
  height: 50px;
  background-color: #ba420b;
  left: 0;
  z-index: 0;
}
.pagination {
  padding-bottom: 20px;
}
.pagination ul {
  background-color: #ea530e;
  float: left;
  width: 100%;
}
.pagination ul li {
  float: left;
  width: 50%;
}
.pagination ul li a {
  text-transform: uppercase;
  display: block;
  color: white;
  font-size: 22px;
  font-weight: 600;
  position: relative;
}
.pagination ul li a:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 0 3px;
}
.pagination ul li a:hover {
  color: rgba(255, 255, 255, 0.7);
}
.pagination ul li:first-child a {
  padding: 20px 0 20px 40px;
}
.pagination ul li:first-child a:after {
  margin-top: 10px;
  left: 20px;
  background-image: url('/img/icons/arrow_l_min.png');
}
.pagination ul li:last-child {
  text-align: right;
}
.pagination ul li:last-child a {
  padding: 20px 40px 20px 0;
}
.pagination ul li:last-child a:after {
  margin-top: 10px;
  right: 20px;
  background-image: url('/img/icons/arrow_r_min.png');
}
.activity-list-title a {
  color: #f37338;
}
.activity-list-title a:hover,
.activity-list-title a:focus {
  text-decoration: none;
  o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #ba420b;
}
