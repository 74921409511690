body {
	.activities {
		display: flex;
		flex-direction: column;
		> figure {
			margin: 0 0 30px;
			padding: 0 0 30px;
			border-bottom: 2px solid #eee;
			i {
				margin: 0 10px 0 0;
			}
			ul.detail {
				li {
					list-style-type: disc;
					margin: 0 0 0 30px;
					line-height: 20px;
				}
			}
			&:last-child {
				border: none;
				padding: 0;
			}
		}
	}
}