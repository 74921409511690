body {
	.header-top {
		img {
			position: absolute;
			top: -30px;
		}
		.col-inner div {
			position: relative;
			&.phone {
				float: right;
			}
			&.main-logo {
				position: relative;
				z-index: 999;
			} 
			&:after,
			&:before {
				content: '';
				z-index: 0;
				position: absolute;
				height: 1000%;
				bottom: 0;
				width: 10000%;
			}
			&:after {
				left: 100%;
			}
			&:before {
				right: 100%;
			}
			ul {
				display: flex; 
				flex-direction: row;
				li {
					margin: 0 28px 0 0;
					&:last-child {
						margin: 0;
					}
				}
			}
		}
	}
	.header-waypoint-sticky.header-main {
		img {
			width: 80px!important;
			top: 5px;
		}
		div.phone {
			display: none;
		}
	}
}

@media(max-width: 1199px) {
	body {
		header.header-main {
			img {
				width: 140px!important;
			}
		}
		section.page-wrapper {
			padding: 0!important;
		}
	}
}
@media(max-width: 991px) {
	body {
		.sidebar-wrapper {
			width: 100%!important;
			position: relative!important;
		}
		section {
			padding: 50px 0;
		}
		section.page-wrapper {
			padding: 50px 0 0!important;
		}
		header.header-main {
			img {
				position: relative;
				min-width: 160px!important;
				top: 0;
			}
			div.header-top {
				display: flex; 
				align-items: center;
				flex-direction: column;
				div.phone {
					display: none;
				}
			}
			div.header-bottom  {
				display: flex; 
				align-items: center;
				flex-direction: column;
				padding: 20px 0 0;
				> div {
					width: 100%!important;
					max-width: 100%!important;
				}
				ul.nav-mini-right {
					float: none;
					display: flex;
					justify-content: center;
					> li {
						margin-right: 30px;
						> a {
							font-size: 20px
						}
					}
				}
			}
			.btn.btn-toggle {
				&:before {
					width: 20px;
					height: 23px;
					line-height: 18px;
					font-size: 29px;
				}
			}
		}
	} 
}
@media(max-width: 991px) {
	div.main-logo {
		a {
			display: flex;
		    justify-content: center;
		}
	}
	body {
		div.body-inner {
			overflow-x: hidden;
		}
		header.header-main {
			div.header-bottom {
				ul.nav-mini-right {
					> li {
						margin-right: 0;
					}
				}
			}
			.btn.btn-toggle {
				&:before {
					width: 45px;
					font-size: 45px;
				}
			}
			.header-mobile-menu {
				.main-menu-nav {
					.main-nav {
						> li > a {

						}
					}
				}
			}
		}
		section.page-wrapper {
			padding: 0 15px!important;
		}
		div.content-wrapper {
			padding-right: 0!important;
		}
		.page-wrapper {
			.sidebar-container {
				margin: 40px 0 0;
				text-align: center;
			}	
		}
	}
}

@media(max-width: 767px) {
	body {
		.main-wrapper{
			.page-static {
				.sidebar-container {
					.sidebar-wrapper {
						> ul {
							> li {
								> ul {
									> li {
										padding: 0!important;
										> a {
											margin: 15px 0;
											font-size: 18px;
										}
									}
								}
							}
							li {
								ul {
									ul {
										padding: 0;
										li {
											padding: 0;
											> a {
												margin: 10px 0;
												font-size: 16px; 
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}