.activity-list-title {
	a {
		color: lighten(@orange, 10%);
		&:hover,
		&:focus {
			text-decoration: none;
			o-transition: all 0.3s ease-out;
			-ms-transition: all 0.3s ease-out;
			-moz-transition: all 0.3s ease-out;
			-webkit-transition: all 0.3s ease-out;
			transition: all 0.3s ease-out;
			color: darken(@orange, 10%);
		}
	}

}