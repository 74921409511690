body {
	.page-static {
		.content-wrapper {
			h2 {
				position: relative;
				text-indent: 0 0 0 30px;
				color: @orange;
				&:after {
					z-index: -1;
					content: '';
					position: absolute;
					width: 100%;
					height: 25%;
					bottom: 25%;
					background-color: #eee;
					left: 0;
				}
			}
			h3 {
				font-size: 26px;
			}
			p {
				font-size: 18px;
				line-height: 24px;
				padding: 5px 0;
				text-align: justify;
				&.information {
					background-color: #eee;
					padding: 15px 30px;
					color: black;
					font-weight: 600;
					font-size: 20px;
				}
			}
			ul {
				padding: 0 0 10px 30px;
				li {
					margin: 0 0 0 30px;
					list-style-type: disc;
					font-size: 18px;
					line-height: 24px;
					padding: 2px 0;
					&:last-child {
						border-bottom: none;
					}
				}
				&:last-child {
					padding: 0 15px 0;
				} 
			}
			ul.links {
				background-color: #eee;
				padding: 20px 30px;
				li {
					margin: 0;
					list-style-type: none;
					a {
						font-weight: 600;
					}
				}
			}
		}
		.sidebar-container {
			overflow: hidden;
			background-color: @orange;
			.sidebar-wrapper {
				a {
					color: white;
				}
				> ul {
					margin: 0 0 40px;
					li {
						padding: 10px 0;
						line-height: 22px;
						&.active {
							> a {
								font-weight: 700;
							}
						}
						a {
							position: relative;
							transition: all 0.5s;
							left: 0;
							&:hover {
								left: 10px;
							}
						}
						ul {
							ul {
								padding: 0 0 0 30px;
							}
						}
						li {
							&.active {
								padding: 20px 0 15px;
								margin: 0;
								background-color: rgba(255,255,255,0.2);
								position: relative;
								a {
									font-weight: 700;
								}
								ul {
									li {
										padding: 0 0 10px;
										a {
											font-weight: 400;
										}
									}
								}
								&:after,
								&:before {
									content: '';
									position: absolute;
									height: 100%;
									width: 200%;
									background-color: rgba(255,255,255,0.2);
									top: 0;
								}
								&:before {
									left: 100%;
								}
								&:after {
									right: 100%;
								}
							}
						}
					}
					> li {
						> a {
							text-transform: uppercase;
							font-size: 32px;
							line-height: 40px;
							margin: 0 0 30px;
							display: block;
						}
						> ul {
							> li {
								padding: 20px 0 10px;
								// border-bottom: 1px solid rgba(255,255,255,0.2);
								> a {
									margin: 0 0 10px;
									display: block;
								}
								&:last-child {
									border: none;
								}
							}
						}
					}
				}
				&.news {
					h4 {
						color: white;
					}	
					ul { 
						li {
							a {
								text-transform: initial;
								font-size: 20px;
								line-height: 30px;
								margin: 0;
							} 
						}
					}
				}
			}
		}
	}
	.col-lg-3 {
		.sidebar-wrapper {
			// width: 260px!important;
			// top: inherit!important;
			// bottom: inherit!important;
		}
	}
	.col-lg-4 {
		.sidebar-wrapper {
			// width: 360px!important;
			// top: inherit;
			// bottom: inherit; 
		}
	}
	.content-wrapper {
		div.partenaires_liste {
			display: flex;
			flex-direction: column;
			ul {
				display: flex;
				flex-direction: row;
				padding: 0 0 20px;
				margin: 0 0 20px;
				border-bottom: 1px solid #eee;
				li {
					list-style-type: none;
					width: 50%;
					display: flex;
					flex-direction: column;
					flex-flow: column wrap;
					&:first-child {
						text-align: center;
					}
					img {
						width: inherit;
						max-width: 200px;
						justify-content: center;
					}
				}
			}
		}
	}
	div.image {
		img {
			width: 100%;
			max-width: 100%;
			height: auto;
			display: block;
		}
	}
}
