
#datepicker {
	&.agenda_home {
		margin: 0;
		height: 462px;
		position: relative;
		z-index: 999;
		.ui-datepicker-header {
			.ui-datepicker-title  {
				span.ui-datepicker-month,
				span.ui-datepicker-year {
					color: white;
				}
				cursor:pointer;
				&:hover {
					opacity: 0.7;
				}
				width: 280px;
				margin-left: 37px;
			}
			.ui-datepicker-prev {
				background-image: url('/web/images/arrow_l_min.png');
			}
			.ui-datepicker-next {
				background-image: url('/web/images/arrow_r_min.png');
			}
		}
		.ui-datepicker-calendar {
			tbody {
				tr {
					td {
						a {
							color: white;
						}
						span {
							color: lighten(@orange, 20%)
						}
						&.ui-datepicker-today {
							a {
								&:after {
									background-color: lighten(@orange, 12%)!important;
								}
							}
						}
					}
					td.with-events{
						a {
							color: white;
							&:after {
								background-color: darken(@orange, 10%);
							}
						}
					}
				}
			}
		}
		&:after {
			background-color: lighten(@orange, 10%);
		}
	}
}

/*** Datepicker Home ***/

.ui-datepicker {
	// top: 460px!important;
}

.ui-datepicker {
	display: none;
	float: left;
	margin: 0;
	padding: 10px 25px 10px 25px;
	background: @orange;
	position: relative;
	.ui-datepicker-header {
		height: 90px;
		.ui-datepicker-prev,
		.ui-datepicker-next {
			position: absolute;
			background-repeat: no-repeat;
			width: 17px;
			height: 28px;
			top: 43px;
			text-indent: -999px;
			overflow: hidden;
			cursor: pointer;
			opacity: 1;
			transition: opacity 0.5s;
			-webkit-transition: opacity 0.5s;
			&:hover {
				opacity: 0.7;
			}
		}
		.ui-datepicker-prev {
			background-image: url('/images/arrow_sprite.png');
			background-position: 3px 2px;
			left: 35px;
		}
		.ui-datepicker-next {
			background-image: url('/images/arrow_sprite.png');
			right: 35px;
			background-position: 1px 2px;
			width: 16px;
		}
		.ui-datepicker-title {
			text-align: center;
			padding: 0;
			display: flex;
			flex-direction: column;
			span {
				display: inline-block;
			}
			span.ui-datepicker-month {
				color: white;
				font-size: 28px;
				width: 100%;
				height: 6px;
			}
			span.ui-datepicker-year {
				color: lighten(@orange, 25%);
				font-size: 18px;
			}
		}
	}
	.ui-datepicker-calendar {
		width: 100%;
		position: relative;
		z-index: 1;
		margin: 0 0 0 5px;
		thead {
			height: 50px;
			line-height: 50px;
			font-size: 20px;
			color: white;
			th {
				text-align: center;
				height: 63px;
			}
		}
		tbody {
			text-align: center;
			tr {
				td {
					font-size: 18px;
					height: 52px;
					line-height: 50px;
					a {
						padding: 0;
						color: white;
						display: block;
						float: left;
						position: relative;
						height: 40px;
						width: 50px;
						&:after {
							content: '';
							position: absolute;
							border-radius: 50%;
							height: 40px;
							width: 40px;
							left: 0;
							top: 4px;
							left: 5px;
							z-index: -1;
							opacity: 1;
							transition: opacity 0.5s;
							webkit-transition: opacity 0.5s;
						}
						&:hover {
							&:after {
								opacity: 0.5;
							}
						}
					}
					span {
						color: lighten(@orange, 30%);
						float: left;
						height: 40px;
						width: 40px;
					}
					&.ui-datepicker-today {
						a {
							color: white;
							&:after {
								background-color: lighten(@orange, 15%);
							}
						}
					}
					&.with-events {
						a {
							&:after {
								background-color: darken(@orange, 10%);
							}
						}
					}
				}
				&:last-child {
					td {
						padding: 0 0 20px;
					}
				}
			}
		}
	}
	&:after{
		content: '';
		position: absolute;
		top: 100px;
		width: 100%;
		height: 50px;
		background-color: darken(@orange, 10%);
		left: 0;
		z-index: 0;
	}
}